<template>
  <div id="AdminTCK">
    <el-main>
      <div class="txt_info">
        注：考生下载准考证前请认真核对照片等个人信息，如有个人信息问题，请与myzb@tsinghua.edu.cn邮箱联系。
      </div>
      <div class="conetnt">
        <div class="header_box">
          <div class="h_logo">
            <img src="../../../assets/images/logo.png"/>
          </div>
          <div class="erq">
            <img id="barcode"/>
          </div>
          <div style="clear: both"></div>
        </div>
        <div class="c_txt">2022年清华大学特殊类型招生考试准考证</div>
        <table cellspacing="0px">
          <tbody>
          <tr>
            <td width="25%">准考证号</td>
            <td width="25%">{{ tableData.zkz_num }}</td>
            <td width="25%">姓名</td>
            <td width="25%">{{ tableData.name }}</td>
          </tr>
          <tr>
            <td>考试名称</td>
            <td>2022年清华大学特殊类型招生考试</td>
            <td>性别</td>
            <td>
                <span v-if="tableData.gender == 1">女</span
                ><span v-else>男</span>
            </td>
          </tr>
          <tr>
            <td>身份证号</td>
            <td colspan="3">{{ tableData.sfz_num }}</td>
          </tr>
          <tr>
            <td :rowspan="tableData.rowspan">
              <img
                :src="
                    tableData.avatar
                      ? tableData.avatar
                      : 'https://spartaimages.oss-cn-beijing.aliyuncs.com/avatar/test.png'
                  "
              />
            </td>
            <td>考试日期</td>
            <td>考试科目</td>
            <td>考试时间</td>
          </tr>
          <tr v-for="(item, index) in tableData.subject" :key="index">
            <td>{{ item.stu_start_time }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.kk_begin_time }}-{{ item.jj_end_time }}</td>
          </tr>
          <tr>
            <td>考区</td>
            <td colspan="3">{{ tableData.province }}</td>
          </tr>
          <tr>
            <td>考点名称</td>
            <td colspan="3">{{ tableData.school_name }}</td>
          </tr>
          <tr>
            <td>考点地址</td>
            <td colspan="3">{{ tableData.school_address }}</td>
          </tr>
          <tr>
            <td>机房位置</td>
            <td colspan="3">{{ tableData.exam_room_address }}{{ tableData.exam_room_name }}</td>
          </tr>
          <tr>
            <td>考位编号</td>
            <td colspan="3">{{ tableData.pc_no }}</td>
          </tr>
          </tbody>
        </table>
        <div class="fy_text">
          <div class="fy_text_sign">防疫要求</div>
          <div class="fy_text_section" v-for="(item, index) in tableData.requirement" :key="index">{{ item }}</div>
        </div>
        <div class="fy_text" v-if="tableData.exam_name=='居家考试'">
          <div class="fy_text_sign"><span>*</span>重要提醒</div>
          <div class="fy_text_section">考生须按照《2022年清华大学特殊类型招生考试考生指南》的要求准备好考试及监控设备，并于4月16日上午9:00-12:00参加统一调试。如因未参加统一调试而导致无法正常进行考试的，相应后果由考生本人承担。</div>
          <div class="fy_text_section">本次网络远程考试指引文档共含2个文件：</div>
          <div class="fy_text_section">1.《2022年清华大学特殊类型招生考试考生指南》</div>
          <div class="fy_text_section">2.《2022年清华大学特殊类型招生考试考场规则》</div>
          <div class="fy_text_section">请考生及时下载，仔细阅读并按要求提前做好相关准备。</div>
        </div>
      </div>
      <div class="btn">
        <el-button @click="getZkzPdf()">下载准考证</el-button>
        <el-button @click="getZnPdf()" v-if="tableData.exam_name=='居家考试'">下载考生指南</el-button>
        <el-button @click="getRulePdf()" v-if="tableData.exam_name=='居家考试'">下载考场规则</el-button>
      </div>
      <template v-if="tableData.exam_name!='居家考试'">
        <!-- 下载安全承诺书 -->
        <div class="txt_info2">
          注：请仔细阅读《清华大学 2022 年本科招生考试考生安全承诺登记表》，根据防疫要求如实填写信息。
        </div>
        <div class="conetnt2">
          <div class="header_box">
            <div></div>
          </div>
          <div class="c_txt">清华大学 2022 年本科招生考试考生安全承诺登记表</div>
          <table cellspacing="0px">
            <tr>
              <td width="15%">测试类型</td>
              <td width="85%" colspan="3">
                <div class="checkbox">
                  <div class="checkbox-item">
                    <div class="checkbox-box"></div>
                    <div>艺术类</div>
                  </div>
                  <div class="checkbox-item">
                    <div class="checkbox-box"></div>
                    <div>体育类</div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td width="15%">姓<span style="padding: 0 1em;"></span>名</td>
              <td width="30%"></td>
              <td width="15%">准考证号</td>
              <td width="40%"></td>
            </tr>
            <tr>
              <td width="15%"><span>省<span style="padding: 0 1em;"></span>份</span></td>
              <td width="30%"></td>
              <td width="15%"><span>中<span style="padding: 0 1em;"></span>学</span></td>
              <td width="40%"></td>
            </tr>
            <tr>
              <td width="15%">联系方式</td>
              <td width="30%"></td>
              <td width="15%">常住地址</td>
              <td width="40%"></td>
            </tr>
            <tr>
              <td width="45%" colspan="2">
                <div style="font-family: '中华软宋'; font-weight: bold">本人14天内到达或途径的城市</div>
                <div>（须与动态行程卡一致）</div>
              </td>
              <td width="55%" colspan="2"></td>
            </tr>
            <tr>
              <td width="45%" colspan="2">
                <div style="font-family: '中华软宋'; font-weight: bold">本人行程卡所涉城市是否带星号*</div>
                <div>（如有星号*，写明具体城市））</div>
              </td>
              <td width="55%" colspan="2"></td>
            </tr>
            <tr>
              <td colspan="4" style="font-family: '中华软宋'; font-weight: bold">
                14天体温记录
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <table cellspacing="0px">
                  <thead>
                  <tr>
                    <td width="100">天数</td>
                    <td width="300">日期</td>
                    <td width="100">体温</td>
                    <td width="100">天数</td>
                    <td width="300">日期</td>
                    <td width="100">体温</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>第1天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第8天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第2天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第9天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第3天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第10天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第4天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第11天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第5天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第12天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第6天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第13天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  <tr>
                    <td>第7天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                    <td>第14天</td>
                    <td>
                      <span>年</span>
                      <span>月</span>
                      <span>日</span>
                    </td>
                    <td><span>℃</span></td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="font-family: '中华软宋'; font-weight: bold">
                本人健康承诺
              </td>
            </tr>
            <tr>
              <td style="text-align: left" colspan="4" rowspan="6">
                <div>
                  1、本人及共同居住家庭成员是否有发热、呼吸道症状等不适症状：
                  是／否
                </div>
                <div>
                  2、本人及共同居住家庭成员是否有新冠肺炎确诊史或疑似就诊史：
                  是／否
                </div>
                <div>
                  3、本人及共同居住家庭成员近14天是否有新冠肺炎确诊、疑似病例或无症状感染者接触史，是否接触过有病例报告社区人员：
                  是／否
                </div>
                <div>
                  4、本人及共同居住家庭成员近21天内是否有境内中高风险地区居住、旅行史：
                  是／否
                </div>
                <div>
                  5、本人及共同居住家庭成员近21天内是否有境外居住、旅行史：
                  是／否
                </div>
              </td>
            </tr>
            <tfoot>
            <tr>
              <td colspan="4" style="text-align: left; padding: 10px">
                上述行程卡信息、体温记录和健康承诺真实有效，本人将密切关注并严格遵守所在省（区、市）及北京市的各项疫情防控要求，自觉配合疫情防控检查，充分理解并遵守考点各项防疫安全要求。遇有发热、乏力、咳嗽、呼吸困难、腹泻等病状出现，本人将及时报告，并立即就医。如因隐瞒病情、活动轨迹或接触史而引起影响公共安全的后果，本人愿承担相应的法律责任。
                <div>考生签字：</div>
                <div>日<span style="width: 2em;"></span>期：</div>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
        <div class="btn">
          <el-button @click="getLetterPdf()">下载安全承诺书</el-button>
        </div>
      </template>
    </el-main>
  </div>
</template>

<script>
  import {SERVER_URL, DEVELOPMENT_SERVER_URL} from "../../../config/server";
  import {getZkzInfo, getZkzPdf} from "r/index/signInfo.js";
  import {formatTime, formatDate} from "s/time.js";

  export default {
    name: "AdminTCK",
    data() {
      return {
        tableData: {},
        htmlTitle: "承诺书",
        nowTime: "",
      };
    },
    created() {
      console.log(window.localStorage.getItem("index-token"), "huoqutoken");

      this.getZkzInfo();
    },
    mounted() {
      JsBarcode("#barcode", this.tableData.zkz_num, {
        format: "CODE39",
        lineColor: "#000",
        background: "#ffffff",
        width: 1,
        height: 20,
        displayValue: true,
      });
    },
    methods: {
      // 获取准考证信息
      async getZkzInfo() {
        // 发送请求的操作
        let {data: res} = await getZkzInfo();
        console.log(res, "准考证信息");
        if (res.code !== 200) return;
        let requirement = [];
        if(res.school_address.requirement){
          requirement = res.school_address.requirement.split("；");
        }
        this.tableData = {
          zkz_num: res.zkz_num,
          name: res.name,
          exam_name: res.exam_name,
          gender: res.gender,
          sfz_num: res.sfz_num,
          avatar: res.avatar,
          subject: res.subject,
          rowspan: res.subject.length + 1,
          city: res.city.name,
          province: res.province.name,
          school_name: res.school_address.name,
          school_address: res.school_address.address,
          exam_room_name: res.exam_room_name,
          exam_room_address: res.exam_room_address,
          pc_no: res.pc_no,
          student_type: res.student_type,
          requirement: requirement,
        };
        this.tableData.subject.sort((a, b) => {
          let value1 = a["kk_begin_time"];
          let value2 = b["kk_begin_time"];
          return value1 - value2;
        });
        this.tableData.subject.forEach((i) => {
          i.stu_start_time = formatDate(i.jj_end_time * 1000);
          i.jj_end_time = formatTime(i.jj_end_time * 1000);
          i.kk_begin_time = formatTime(i.kk_begin_time * 1000);
        });
        this.createQrCode();
      },

      // 生成一维码
      createQrCode() {
        JsBarcode("#barcode", this.tableData.zkz_num, {
          format: "CODE128",
          lineColor: "#000",
          background: "#ffffff",
          width: 1,
          height: 30,
          displayValue: true,
          fontSize: 12,
          margin: 0,
        });
      },
      // 下载考场规则
      async getRulePdf() {
        // 发送请求的操作
        let {data: res} = await getZkzPdf();
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        window.open(
          SERVER_URL +
          "web/zkz/gzForm?student_id=" +
          res.data.student_id +
          "&exam_uuid=" +
          res.data.exam_uuid
        );
      },
      // 下载考生指南
      async getZnPdf() {
        // 发送请求的操作
        let {data: res} = await getZkzPdf();
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        window.open(
          SERVER_URL +
          "web/zkz/juJiaForm?student_id=" +
          res.data.student_id +
          "&exam_uuid=" +
          res.data.exam_uuid +
          "&student_type=" +
          this.tableData.student_type
        );
      },
      // 下载pdf
      async getZkzPdf() {
        // 发送请求的操作
        let {data: res} = await getZkzPdf();
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        window.open(
          SERVER_URL +
          "web/zkz/download?student_id=" +
          res.data.student_id +
          "&exam_uuid=" +
          res.data.exam_uuid
        );
      },
      //下载承诺书pdf
      async getLetterPdf() {
        // 发送请求的操作
        let {data: res} = await getZkzPdf();
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        window.open(
          SERVER_URL +
          "web/zkz/registryForm?student_id=" +
          res.data.student_id +
          "&exam_uuid=" +
          res.data.exam_uuid
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  #AdminTCK {
    width: 100%;
    height: 100%;

    .el-main {
      max-width: 1400px;
      margin: 0 auto;
      padding: 0;

      .txt_info {
        width: 794px;
        text-align: left;
        margin: 0 auto;
        color: #f59a23;
        font-size: 14px;
        font-family: "微软雅黑";
        font-weight: normal;
        padding: 30px 0 24px 0;
        // margin: 30px 0 24px 0;
      }

      .txt_info2 {
        width: 794px;
        text-align: left;
        margin: 0 auto;
        color: #f59a23;
        font-size: 14px;
        font-family: "微软雅黑";
        font-weight: normal;
        padding: 40px 0 24px 0;
        // margin: 30px 0 24px 0;
      }

      .conetnt {
        width: 694px;
        height: 1022px;
        margin: 0 auto;
        padding: 50px;
        background-color: #ffffff;

        .header_box {
          position: relative;

          .h_logo {
            float: left;

            img {
              width: 160px;
            }
          }

          .erq {
            float: right;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .c_txt {
          text-align: center;
          padding: 22px 0;
          font-size: 26px;
          font-weight: bold;
          font-family: '华文中宋';
        }

        .fy_text {
          /*position: relative;*/
          /*left: 10px;*/
          /*top: 10px;*/
          padding: 10px;

          .fy_text_sign {
            position: relative;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;

            > span {
              position: absolute;
              left: -8px;
              color: red;
            }
          }

          .fy_text_section {
            font-family: "仿宋";
            font-size: 14px;
            width: 640px;
            line-height: 24px;
          }
        }

        table {
          width: 100%;
          border: 2px solid #000000;

          tr {
            width: 100%;

            td {
              font-family: "仿宋";
              color: #333333;
              font-size: 14px;
              text-align: center;
              border-right: 1px solid #666666;
              border-bottom: 1px solid #666666;
              height: 40px;

              &:last-child {
                border-right: 0;
              }

              img {
                // width: 100%;
                height: 200px;
                border: 0;
              }
            }

            &:last-child {
              td {
                border-bottom: 0;
              }
            }
          }
        }
      }

      .conetnt2 {
        width: 694px;
        height: 1082px;
        margin: 0 auto;
        padding: 20px 50px;
        background-color: #ffffff;

        .header_box {
          position: relative;

          .h_logo {
            float: left;

            img {
              width: 160px;
            }
          }

          .erq {
            float: right;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .c_txt {
          text-align: center;
          padding: 22px 0;
          font-size: 26px;
          font-weight: bold;
          font-family: "华文中宋";
        }

        .fy_text {
          position: relative;
          left: 10px;
          top: 10px;

          .fy_text_sign {
            font-weight: bold;
            font-size: 14px;
          }

          .fy_text_section {
            font-size: 12px;
            width: 640px;
          }
        }

        table {
          width: 100%;
          border: 2px solid #000000;

          tr {
            width: 100%;

            table {
              border: 0;
              padding: 0px;

              thead {
                tr {
                  td {
                    font-family: "仿宋";
                    color: #333333;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    border-right: 1px solid #666666;
                    border-bottom: 1px solid #666666;
                    height: 28px;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-family: "仿宋";
                    color: #333333;
                    font-size: 14px;
                    text-align: center;
                    border-top: 1px solid #666666;
                    height: 28px;

                    span {
                      padding-left: 20px;
                    }
                  }
                }
              }
            }

            td {
              font-family: "仿宋";
              color: #333333;
              font-size: 14px;
              text-align: center;
              border-right: 1px solid #666666;
              border-bottom: 1px solid #666666;
              height: 40px;

              > span {
                padding: 0 1em;
              }

              div {
                line-height: 25px;
                padding-left: 10px;
              }

              .checkbox {
                padding: 0;
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;

                .checkbox-item {
                  display: flex;
                  flex-flow: row;
                  justify-content: center;
                  align-items: center;
                  padding: 0 80px 0 0;

                  &:last-child {
                    padding: 0;
                  }

                  .checkbox-box {
                    width: 12px;
                    height: 12px;
                    line-height: 12px;
                    padding: 0;
                    border: 1px solid #000000 !important;
                  }

                  > div {
                    padding-left: 5px;
                  }
                }

              }

              &:last-child {
                border-right: 0;
              }

              img {
                // width: 100%;
                height: 200px;
                border: 0;
              }
            }

            &:last-child {
              td {
                border-bottom: 0;
              }
            }
          }

          tfoot {
            tr {
              td {
                text-indent: 26px;
                line-height: 25px;

                div {
                  font-weight: bold;
                  font-size: 20px;
                  width: 100%;
                  padding-left: 400px;
                  margin-top: 10px;
                  padding-bottom: 10px;
                  font-family: "仿宋";
                }
              }
            }
          }
        }
      }

      .btn {
        width: 694px;
        padding: 0 50px;
        margin: 20px auto;
        display: flex;
        flex-flow: row;

        .el-button {
          width: 694px;
          height: 60px;
          border-radius: 8px;
          background-color: #601b9b;
          color: #ffffff;
          font-size: 20px;
        }
      }
    }
  }

  ::v-deep .vue-barcode-element {
    width: 200px;
    height: 60px;

    rect {
      height: 60px;
    }
  }
</style>
