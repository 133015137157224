import { request } from "./network";

// 获取准考证信息
export function getZkzInfo(data) {
  return request({
    method: "post",
    url: "/web/zkz/getZkzInfo",
    data
  });
}
// 获取准考证PDF
export function getZkzPdf(data) {
  return request({
    method: "post",
    url: "/web/zkz/getZkzPdf",
    data
  });
}
// 下载PDF
export function downloadPdf(params) {
  return request({
    method: "get",
    url: "/web/zkz/download",
    params
  });
}

